import '../css/Formulario.css';
import { useState } from 'react';
import Axios from 'axios';
import config from '../config.json';
import Select from 'react-select';
import { useLoaderData } from 'react-router-dom';

export const formularioJuegoLoader = async () => {
    const resultado = await Axios.get(config.IP_SERVER+'/playerList');
    return resultado.data;
};

export default function FormularioJuego () {

    const [dateGame, setDateGame] = useState(null);
    const [primerJugador, setPrimerJugador] = useState(null);
    const [segundoJugador, setSegundoJugador] = useState(null);
    const [showMessage, setShowMessage] = useState(true);
    const [textMessage, setTextMessage] = useState('');
    const [errorCss, setErrorCss] = useState(false);
    const playerList = useLoaderData();
    const [checkedState, setCheckedState] = useState(() => {
        //Creamos el array para los checks
        let array = [];
        for(let i = 0; i < playerList.length; i++){
            array.push({
                id:playerList[i].id,
                name:playerList[i].name,
                checked:false 
            });
        }
        return array;
    });

    const validador = () => {

        if(!dateGame){
            console.log('Entra 2');
            setShowMessage(false);
            setTextMessage('Es necesario rellenar la fecha del juego.');
            setErrorCss(true);
            return false;
        }
        if(!primerJugador){
            console.log('Entra 2');
            setShowMessage(false);
            setTextMessage('Es necesario seleccionar al primer ganador.');
            setErrorCss(true);
            return false;
        }
        if(!segundoJugador){
            console.log('Entra 2');
            setShowMessage(false);
            setTextMessage('Es necesario seleccionar al segundo ganador.');
            setErrorCss(true);
            return false;
        }

        return true;
    }

    
    const addGame = async () => {
        
        if(validador()){
            Axios.post(config.IP_SERVER+'/createGame',{
                dateGame: dateGame,
            }).then((e) => {
                //Creamos los participantes
                let idGame = e.data.result.insertId;
    
                for(let i = 0; i<checkedState.length; i++){

                    if(checkedState[i].checked){
                        let points = 0;
                        if(playerList[i].id === primerJugador.value){
                            points = 3;
                        }
                        else if (playerList[i].id === segundoJugador.value){
                            points = 1;
                        }
                        else{
                            points = 0;
                        }
                        Axios.post(config.IP_SERVER+'/createPlayerInGame',{
                            idGame: idGame,
                            idPlayer: playerList[i].id,
                            namePlayer: playerList[i].name,
                            points: points
                        });
                    }
                }
                
                gestionarMensaje(e);
            });
        }
        
    };

    const gestionarMensaje = (e) => {
        setTextMessage(e.data.description);
        setShowMessage(false);
        if(e.data.code !== '0'){
            setErrorCss(true);
        }
        else{
            setErrorCss(false);
        }
    };

    const manejarFecha = e => {
        setDateGame(e.target.value);
        setShowMessage(true);
    };

    const manejarPrimerJugador = e => {
        setPrimerJugador(e);
        setShowMessage(true);
        activarCheckPorGanador(e.label);
    };

    const manejarSegundoJugador = e => {
        setSegundoJugador(e);
        setShowMessage(true);
        activarCheckPorGanador(e.label);
    };

    const activarCheckPorGanador = (name) => {
        const updatedCheckedState = checkedState.map((check) => {
            if(check.name === name){
            return {
                ...check,
                checked: true
            };
          }
          else{
            return check;
          }
        });
        setCheckedState(updatedCheckedState);
    };

    const manejarCheck = (name) => {
        const updatedCheckedState = checkedState.map((check) => {
            if(check.name === name){
            return {
                ...check,
                checked: !check.checked
            };
          }
          else{
            return check;
          }
        });
        setCheckedState(updatedCheckedState);
    }

    return (
        <>
            <h1>Nuevo Juego</h1>
            <div className='form-jug'>
                <input 
                    className='form-jue-input'
                    type='date'
                    placeholder='Fecha'
                    name='date'
                    onChange={manejarFecha}
                />
                <Select className='form-jue-input' placeholder='Primer Ganador' name='primerJugador'
                    options={playerList.map((juego) => 
                        ({
                            label: juego.name,
                            value: juego.id
                        })
                    )}
                    onChange={manejarPrimerJugador}
                />
                <Select className='form-jue-input' placeholder='Segundo Ganador' name='segundoJugador'
                    options={playerList.map((juego) => 
                        ({
                            label: juego.name,
                            value: juego.id
                        })
                    )}
                    onChange={manejarSegundoJugador}
                />
                <h2>Participantes</h2>
                <ul className='list-ul' >
                    {playerList.map(({id, name}, index) => {
                        return (
                            <li className='list-li' key={index}>
                                <input 
                                    className='list-input'
                                    type='checkbox'
                                    id={'checkbox-'+index}
                                    name={name}
                                    value={name}
                                    checked={checkedState[index].checked}
                                    onChange={() => manejarCheck(name)}
                                />
                                <label className='list-label' htmlFor={'checkbox-'+index}>{name}</label>
                            </li>
                        );
                    })}
                </ul>
                <button className='form-jug-btn' onClick={addGame}>Agregar Juego</button>
            </div>
            <p className={errorCss ? 'form-message-error' : 'form-message'} hidden={showMessage}>{textMessage}</p>
        </>
    );

};