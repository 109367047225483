import '../css/ListaJugadores.css';
import Axios from 'axios';
import config from '../config.json';
import Juego from './Juego.js';
import { useLoaderData } from 'react-router-dom';

export const listaJuegosLoader = async () => {
    const resultado = await Axios.get(config.IP_SERVER+'/gameList');
    return resultado.data;
};

export default function ListaJuegos(){
    
    const loadedData = useLoaderData();

    const deleteGame = async id => {
        Axios.post(config.IP_SERVER+'/deleteGame', {
            idGame: id
        }).then((e) => {
            window.location.reload(false);
        });
    }

    return (
        <div className='list-jug'>
            <h1>Lista de Juegos</h1>
            {loadedData && loadedData.games.length ? (
                loadedData.games.map((juego) => 
                    
                    <Juego
                        key={juego.idGame}
                        id = {juego.idGame}
                        date = {juego.dateGame}
                        winners = {loadedData.winners.filter(winner => winner.idGame === juego.idGame)}
                        participants = {loadedData.participants.filter(participant => participant.idGame === juego.idGame)}
                        eliminarJuego = {deleteGame}
                    />
                )
            ) : (
                <p>No hay juegos registrados</p>
            )}
        </div>
    );
};