import '../css/Gestion.css';
import { Outlet, Link } from 'react-router-dom';

export default function GestionJuegos() {
    return (
        <>
            <div className='gest-cabecera'>
                <h1>Gestión de Jugadores</h1>
                <div className='gest-botonera'>
                    <Link className='gest-boton' to={'ListaJuegos'}>Lista Juegos</Link>
                    <Link className='gest-boton' to={'NuevoJuego'}>Nuevo Juego</Link>
                </div>
            </div>
            <div className='gest-details'>
                <Outlet/>
            </div>
        </>
    );
}