import '../css/Ranking.css';
import { useMemo } from 'react';
import config from '../config.json';
import Axios from 'axios';
import {useTable} from 'react-table';
import { useLoaderData } from 'react-router-dom';

export const rankingLoader = async () => {
    const resultado = await Axios.get(config.IP_SERVER+'/rankingList');
    return resultado.data;
};

export default function Ranking() {

    const data = useLoaderData();

    const columns = useMemo(() => [
        {
            Header: 'Jugador',
            accessor: 'namePlayer',
        },
        {
            Header: 'Puntos',
            accessor: 'points',
        },
        {
            Header: 'Nº Juegos',
            accessor: 'numGames',
        },
        {
            Header: '% Ganados',
            accessor: 'porcentajeGanados',
        },
    ],
    []
    );

    const {getTableProps, getTableBodyProps, headerGroups, rows, prepareRow} = useTable({columns, data});

    return (
        <div className='div-table'> 
            <h1>Ranking</h1>
            <table className='table' {...getTableProps()}>
                <thead>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>{headerGroup.headers.map(column => (
                                <th className='table-th'{...column.getHeaderProps()}>{column.render('Header')}</th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {rows.map(row => {prepareRow(row)
                        return (
                            <tr {...row.getRowProps()}>{row.cells.map(cell => {
                                    return (
                                        <td className='table-td'{...cell.getCellProps()}>{cell.render('Cell')}</td>
                                    )
                                })}
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </div>
    );
}