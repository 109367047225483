import '../css/ListaJugadores.css';
import Axios from 'axios';
import Jugador from './Jugador.js';
import config from '../config.json';
import { useLoaderData } from 'react-router-dom';

export const listaJugadoresLoader = async () => {
    const resultado = await Axios.get(config.IP_SERVER+'/playerList');
    return resultado.data;
};

export default function ListaJugadores(){
    
    const playerList = useLoaderData();

    const deletePlayer = async id => {
        Axios.post(config.IP_SERVER+'/deletePlayer', {
            idPlayer: id
        }).then((e) => {
            window.location.reload(false);
        });
    }

    return (
        <div className='list-jug'>
            <h1>Lista de Jugadores</h1>
            {playerList.length ? (
                playerList.map((jugador) => 
                    
                    <Jugador
                        key={jugador.id}
                        id = {jugador.id} 
                        texto = {jugador.name}
                        eliminarJugador = {deletePlayer}
                    />
                )
            ) : (
                <p>No hay jugadores</p>
            )}
        </div>
    );
};