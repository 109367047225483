import '../css/Jugador.css';
import { AiOutlineCloseCircle } from 'react-icons/ai'

function Jugador ({ id, texto, eliminarJugador }) {

	return (
		<div className='div-jug'>
				<div className='div-jug-texto'>
					{texto}
				</div>
				<AiOutlineCloseCircle className='div-jug-icono' onClick={() => eliminarJugador(id)}/>
		</div>
	);

};

export default Jugador;