import './App.css';
import logo from './img/logoPocha.png';
import { Link, Outlet } from 'react-router-dom';


export default function App() {
  return (
    <>
      <div className='app-container'>
        <div className='app-cabecera'>
          <img src={logo} className='app-logo' alt='Logo' />
          <div className='app-botonera'>
            <Link className='app-boton' to={'gestionJugadores'}>Gestión Jugadores</Link>
            <Link className='app-boton' to={'gestionJuegos'}>Gestión Juegos</Link>
            <Link className='app-boton' to={'ranking'}>Ranking</Link>
          </div>
        </div>
        <div className='app-detail'>
          <Outlet/>
        </div>
      </div>
      
    </>
  );
}
