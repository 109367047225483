import '../css/Juego.css';
import { AiOutlineCloseCircle, AiOutlineInfoCircle } from 'react-icons/ai'
import { useState } from 'react';

export default function Juego ({ id, date, winners, participants, eliminarJuego }) {

	const [showData, setShowData] = useState(true);

	const manejarData = () => {
		setShowData(!showData);
	};

	return (
		<>
			<div className='div-juego-cabecera'>
				<div className='div-juego-fecha' >
					{date}
				</div>
				<AiOutlineInfoCircle className='div-jug-icono' onClick={manejarData}/>
				<AiOutlineCloseCircle className='div-jug-icono' onClick={() => eliminarJuego(id)}/>
			</div>
			<div className='div-textos' hidden={showData} onClick={manejarData}>
				<h2>Participantes</h2>
				<div className='div-participants'>
					{winners.map((winner) => 
						<div className='div-participant-texto' key={winner.id}>
							{winner.tipo+' '+winner.namePlayer}
						</div>
					)}
					{participants.map((participant) => 
						<div className='div-participant-texto' key={participant.id}>
							{participant.namePlayer}
						</div>
					)}
				</div>
			</div>
		</>
	);

};