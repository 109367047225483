import '../css/Gestion.css';
import { Outlet, Link } from 'react-router-dom';

export default function GestionJugadores() {
    return (
        <>
            <div className='gest-cabecera'>
                <h1>Gestión de Jugadores</h1>
                <div className='gest-botonera'>
                    <Link className='gest-boton' to={'ListaJugadores'}>Lista Jugadores</Link>
                    <Link className='gest-boton' to={'NuevoJugador'}>Nuevo Jugador</Link>
                </div>
            </div>
            <div className='gest-details'>
                <Outlet/>
            </div>
        </>
    );
}