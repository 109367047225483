import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import App from './App.js';
import ErrorPage from './components/ErrorPage.js';
import GestionJugadores from './components/GestionJugadores.js';
import ListaJugadores, {listaJugadoresLoader} from './components/ListaJugadores.js';
import FormularioJugadores from './components/FormularioJugadores.js';
import GestionJuegos from './components/GestionJuegos.js';
import FormularioJuego, {formularioJuegoLoader} from './components/FormularioJuego.js';
import Ranking, {rankingLoader} from './components/Ranking.js';
import ListaJuegos, {listaJuegosLoader} from './components/ListaJuegos.js';

const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: 'gestionJugadores',
        element: <GestionJugadores/>,
        children: [
          {
            path: 'ListaJugadores',
            element: <ListaJugadores/>,
            loader: listaJugadoresLoader
          },
          {
            path: 'NuevoJugador',
            element: <FormularioJugadores />,
          },
        ],
      },
      {
        path: 'gestionJuegos',
        element: <GestionJuegos />,
        children: [
          {
            path: 'ListaJuegos',
            element: <ListaJuegos />,
            loader: listaJuegosLoader
          },
          {
            path: 'NuevoJuego',
            element: <FormularioJuego />,
            loader: formularioJuegoLoader
          },
        ],
      },
      {
        path: 'ranking',
        element: <Ranking />,
        loader: rankingLoader
      },
    ],
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
