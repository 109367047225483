import '../css/Formulario.css';
import { useState } from 'react';
import Axios from 'axios';
import config from '../config.json';

function FormularioJugadores () {

    const [namePlayer, setNamePlayer] = useState('');
    const [showMessage, setShowMessage] = useState(true);
    const [textMessage, setTextMessage] = useState('');
    const [errorCss, setErrorCss] = useState(false);

    const validador = () => {
        if(!namePlayer){
            setShowMessage(false);
            setTextMessage('Es necesario rellenar el nombre del jugador');
            setErrorCss(true);
            return false;
        }

        return true;
    };

    const addPlayer = () => {
        
        if(validador()){
            Axios.post(config.IP_SERVER+'/createPlayer', {
                namePlayer: namePlayer
            }).then((e) => {
                setTextMessage(e.data.description);
                setShowMessage(false);
                if(e.data.code !== '0'){
                    setErrorCss(true);
                }
                else{
                    setErrorCss(false);
                }
                setNamePlayer('');
            });
        }
        
    };

    const manejarCambio = e => {
        setNamePlayer(e.target.value);
        setShowMessage(true);
    };


    return (
        <>
            <h1>Nuevo Jugador</h1>
            <div className='form-jug'>
                <input 
                    className='form-jug-input'
                    type='text'
                    placeholder='Nombre'
                    name='namePlayer'
                    onChange={manejarCambio}
                />
                <button className='form-jug-btn' onClick={addPlayer}>Agregar Jugador</button>
            </div>
            <p className={errorCss ? 'form-message-error' : 'form-message'} hidden={showMessage}>{textMessage}</p>
        </>
    );

};

export default FormularioJugadores;